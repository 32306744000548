import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { TestCloudFunction } from "../../components/TestCloudFunctions.js";
export const _frontmatter = {
  "title": "Creating a serverless REST API",
  "description": "What is REST and how does it work? How do you build a serverless API?",
  "image": "./img/serverless-rest-api.png"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h1 {...{
      "id": "creating-a-rest-api"
    }}>{`Creating a REST API`}</h1>
    <p><img parentName="p" {...{
        "src": "/bebd5231abba61017588537ed36fe77b/serverless-rest-api.svg",
        "alt": null
      }}></img></p>
    <p>{`You've heard of REST before, but what `}<strong parentName="p"><em parentName="strong">{`is`}</em></strong>{` REST? How does it work? Can you build one from scratch? Does serverless make your REST life easier?`}</p>
    <p>{`In this chapter, learn about REST best practices and finish with a small implementation you can try right now. I left mine running ✌️`}</p>
    <h2 {...{
      "id": "what-is-rest"
    }}>{`What is REST`}</h2>
    <p><a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Representational_state_transfer"
      }}>{`REST`}</a>{` stands for REpresentational State Transfer. Coined in `}<a parentName="p" {...{
        "href": "https://www.ics.uci.edu/~fielding/pubs/dissertation/top.htm"
      }}>{`Roy Fielding's 2000 doctoral thesis`}</a>{`, it now represents the standard approach to web APIs.`}</p>
    <p>{`Fielding says REST is an architectural principle. It `}<em parentName="p">{`recommends`}</em>{` how to build a scalable web system, but there's no official standard.`}</p>
    <p>{`You may have noticed this in the wild. RESTful APIs follow similar guidelines and no two are alike.`}</p>
    <p><strong parentName="p">{`These days any API that uses HTTP to transfer data and URLs to identify resources is called REST.`}</strong></p>
    <p>{`Here are the 6 architectural constraints that define a RESTful system 👇`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`client-server architecture`}</strong>{` specifies a separation of concerns between the user interface and the storage of data. This simplifies both sides and lets them evolve independently.`}</li>
      <li parentName="ul"><strong parentName="li">{`statelessness`}</strong>{` specifies that `}<em parentName="li">{`the protocol`}</em>{` is stateless. Each request to the server contains all information necessary to process that request. Servers do not maintain client context.`}</li>
      <li parentName="ul"><strong parentName="li">{`cacheability`}</strong>{` specifies that clients can cache any server response to improve performance on future requests. Servers have to annotate responses with appropriate caching policies via http headers`}</li>
      <li parentName="ul"><strong parentName="li">{`layered system`}</strong>{` means that, like regular HTTP, a RESTful client shouldn't need to know whether it's talking to a server, a proxy, or load balancer. This improves scalability.`}</li>
      <li parentName="ul"><strong parentName="li">{`code on demand (optional)`}</strong>{` says that servers can send executable code as part of their responses. You see this with web servers sending JavaScript.`}</li>
      <li parentName="ul"><strong parentName="li">{`uniform interface`}</strong>{` is the most fundamental and means that clients can interact with a server purely from responses, without outside knowledge.`}</li>
    </ul>
    <h3 {...{
      "id": "a-uniform-interface"
    }}>{`A uniform interface`}</h3>
    <p>{`Creating a uniform interface is the most important aspect of a RESTful API. The less clients know about your server, the better.`}</p>
    <p>{`Each `}<strong parentName="p">{`request identifies the resource`}</strong>{` it is requesting. Using the URL itself.`}</p>
    <p><strong parentName="p">{`Responses send a representation`}</strong>{` of a resource rather than the resource itself. Like compiling a set of database objects into a JSON document.`}</p>
    <p>{`All `}<strong parentName="p">{`messages are self-descriptive`}</strong>{` meaning both client and server can understand a message without external information. Send everything you need.`}</p>
    <p>{`A resource's `}<strong parentName="p">{`representation includes everything needed to modify`}</strong>{` that resource. When clients get a response, it should contain everything necessary to modify or delete the underlying resources.`}</p>
    <p>{`Academics say `}<strong parentName="p">{`responses should list possible actions`}</strong>{` so clients can navigate a system without intimate knowledge of its API. You rarely see this in the wild.`}</p>
    <h2 {...{
      "id": "designing-a-restful-api"
    }}>{`Designing a RESTful API`}</h2>
    <p>{`The trickiest part of building a RESTful API is how it evolves over time. The second trickiest is keeping it consistent across your app.`}</p>
    <p>{`As your system grows you're tempted to piggy-back on existing APIs and break resource constraints. You're likely to forget the exact wording and phrasing of different parts.`}</p>
    <p>{`All that is natural. `}<strong parentName="p">{`The important part is to start on the right foot and clean up when you can.`}</strong></p>
    <h3 {...{
      "id": "engineers-are-human"
    }}>{`Engineers are human`}</h3>
    <p>{`When engineers `}<em parentName="p">{`can`}</em>{` do something with your API, they will.`}</p>
    <p>{`They're going to find every undocumented feature, discover every "alternative" way to get data and uncover any easter egg you didn't mean to include. They're good at their job.`}</p>
    <p>{`Do yourself a favor and aim to `}<strong parentName="p">{`keep everything consistent`}</strong>{`. The more consistent you are, the easier it will be to clean up.`}</p>
    <p>{`That means`}</p>
    <ul>
      <li parentName="ul">{`all dates in the same format`}</li>
      <li parentName="ul">{`all responses in the same shape`}</li>
      <li parentName="ul">{`keep field types consistent, if an error is a string it's always a string`}</li>
      <li parentName="ul">{`include every field name and value in full`}</li>
      <li parentName="ul">{`when multiple endpoints include the same model, make it `}<em parentName="li">{`the same`}</em></li>
    </ul>
    <p>{`Here are tips I've picked up over the past 14 years of building and using RESTful APIs.`}</p>
    <h3 {...{
      "id": "url-schema"
    }}>{`URL schema`}</h3>
    <p>{`Your URL schema exists to solve `}<em parentName="p">{`one`}</em>{` problem: Create a uniform way to identify resources and endpoints on your server.`}</p>
    <p>{`Keep it consistent, otherwise don't sweat it.`}</p>
    <p>{`Engineers like to get stuck on pointless details, but it's okay. Make sure your team agrees on what makes sense.`}</p>
    <p>{`When designing a URL schema I aim to:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`make it guessable`}</strong>{`, which stems from consistency and predictability`}</li>
      <li parentName="ul"><strong parentName="li">{`make it human readable`}</strong>{`, which makes it easier to use, debug, and memorize`}</li>
      <li parentName="ul"><strong parentName="li">{`avoid query strings`}</strong>{` because they look messy and can make copy paste debugging harder`}</li>
      <li parentName="ul"><strong parentName="li">{`avoid strange characters`}</strong>{` like spaces and emojis. It looks cute, but it's cumbersome to work with`}</li>
      <li parentName="ul"><strong parentName="li">{`include IDs in URLs`}</strong>{` because it makes debugging and logging easier`}</li>
    </ul>
    <p>{`The two schemas I like, go like this:`}</p>
    <pre><code parentName="pre" {...{}}>{`https://api.wonderfulservice.com/<namespace>/<model>/<id>
`}</code></pre>
    <p>{`Using an `}<inlineCode parentName="p">{`api.*`}</inlineCode>{` subdomain helps with load balancing and using special servers for your API. Less relevant in the serverless world because providers create unique domains.`}</p>
    <p>{`The optional `}<inlineCode parentName="p">{`<namespace>`}</inlineCode>{` helps you stay organized. As your app grows, you'll notice different areas use similar-sounding names.`}</p>
    <p>{`You don't need a namespace for generic models like `}<inlineCode parentName="p">{`user`}</inlineCode>{` or `}<inlineCode parentName="p">{`subscription`}</inlineCode>{`.`}</p>
    <p>{`Adding a `}<inlineCode parentName="p">{`<model>`}</inlineCode>{` that's named after the model on your backend helps you stay sane. Yes it breaks the idea of total separation between client and server, but it's useful to maintain consistency.`}</p>
    <p><strong parentName="p">{`If everyone calls everything the same name, you never need to translate. 😉`}</strong></p>
    <p>{`The `}<inlineCode parentName="p">{`<id>`}</inlineCode>{` identifies the specific instance of a model that you're changing.`}</p>
    <p>{`Sometimes it's useful to use this alternative schema:`}</p>
    <pre><code parentName="pre" {...{}}>{`https://api.wonderfulservice.com/<namespace>/<model>/<verb>/<id>
`}</code></pre>
    <p>{`The verb specifies what you're doing to the model. More on that when we discuss `}<a parentName="p" {...{
        "href": "#using-http-verbs"
      }}>{`HTTP verbs`}</a>{` further down.`}</p>
    <h3 {...{
      "id": "data-format"
    }}>{`Data format`}</h3>
    <p>{`Use JSON. Both for sending data and for receiving data.`}</p>
    <div id="lock" />
    <p>{`Great support in common programming languages, easy to use with JavaScript, simple to write by hand, readable by humans, not verbose. That makes JSON the perfect format for a modern API.`}</p>
    <p><strong parentName="p">{`For timestamps`}</strong>{` I recommend the `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/ISO_8601"
      }}>{`ISO 8601 standard`}</a>{` for the same reason. Great tooling in all languages, readable by humans, writable by hand.`}</p>
    <p><a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Unix_time"
      }}>{`UNIX timestamps`}</a>{` used to be popular and are falling out of favor. Hard to read for humans, don't work great with dates beyond 2038, bad at dates before 1970.`}</p>
    <p>{`Stick to ISO time and JSON data 😊`}</p>
    <h3 {...{
      "id": "using-http-verbs"
    }}>{`Using HTTP verbs`}</h3>
    <p>{`Verbs specify `}<em parentName="p">{`what`}</em>{` your request does with a resource.`}</p>
    <p>{`Opinions on how to pass verbs from client to server vary. There's 3 camps:`}</p>
    <ol>
      <li parentName="ol">{`stick to HTTP verbs`}</li>
      <li parentName="ol">{`verbs belong in the URL`}</li>
      <li parentName="ol">{`verbs are part of the JSON payload`}</li>
    </ol>
    <p>{`Everyone agrees that a `}<inlineCode parentName="p">{`GET`}</inlineCode>{` request is for getting data and should have no side-effects.`}</p>
    <p>{`Other verbs belong to the `}<inlineCode parentName="p">{`POST`}</inlineCode>{` request. Or you can use HTTP verbs like `}<inlineCode parentName="p">{`PUT`}</inlineCode>{`, `}<inlineCode parentName="p">{`PATCH`}</inlineCode>{`, and `}<inlineCode parentName="p">{`DELETE`}</inlineCode>{`.`}</p>
    <p>{`I like to use a combination.`}</p>
    <p><inlineCode parentName="p">{`GET`}</inlineCode>{` for getting data. `}<inlineCode parentName="p">{`POST`}</inlineCode>{` for posting data (both create and update). `}<inlineCode parentName="p">{`DELETE`}</inlineCode>{` for deleting ... on the rare occasion I let clients delete data.`}</p>
    <p><inlineCode parentName="p">{`PUT`}</inlineCode>{` and `}<inlineCode parentName="p">{`PATCH`}</inlineCode>{` can be frustrating to work with in client libraries.`}</p>
    <h3 {...{
      "id": "errors"
    }}>{`Errors`}</h3>
    <p>{`Should you use HTTP error codes to communicate errors?`}</p>
    <p>{`Opinions vary.`}</p>
    <p>{`One camp says that HTTP errors are for HTTP-layer problems. Your server being down, a bad URL, invalid payload, etc. When your application processes a request and decides there's an error, it should return 200 with an error object.`}</p>
    <p>{`The other camp says that's silly and we already have a great system for errors. Your application should use the full gamut of HTTP error codes `}<em parentName="p">{`and return an error object`}</em>{`.`}</p>
    <p><strong parentName="p">{`Always return a descriptive JSON error object.`}</strong>{` That's a given. Make sure your server doesn't return HTML when there's an error.`}</p>
    <p>{`An error shape like:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
  "status": "error",
  "error": "This is what went wrong"
}
`}</code></pre>
    <p>{`is best.`}</p>
    <p>{`I like to combine both approaches. `}<inlineCode parentName="p">{`500`}</inlineCode>{` errors for my application failing to do its job, `}<inlineCode parentName="p">{`404`}</inlineCode>{` for things that aren't found, `}<inlineCode parentName="p">{`200`}</inlineCode>{` with an explanation for everything else. Helps avoid hunting for obscure error codes and sticks to the basics of HTTP.`}</p>
    <p>{`Added bonus: You can read the error object. Will you remember what error 418 means?`}</p>
    <h3 {...{
      "id": "versioning"
    }}>{`Versioning`}</h3>
    <p>{`I have given up on versioning APIs.`}</p>
    <p>{`Your best bet is to maintain eternal backwards compatibility. Make additive changes when needed, remove things never.`}</p>
    <p>{`It leads to messy APIs, which is a shame, but better than crashing an app the user hasn't updated in 3 years.`}</p>
    <p>{`When you need a breaking change, it's unlikely the underlying model fits your current URL schema. Use a new URL.`}</p>
    <h2 {...{
      "id": "build-a-simple-rest"
    }}>{`Build a simple REST`}</h2>
    <p>{`To show you how this works in practice, we're going to build a serverless REST API. Accepts and returns JSON blobs, stores them in DynamoDB.`}</p>
    <p>{`You can `}<a parentName="p" {...{
        "href": "https://github.com/Swizec/serverlesshandbook.dev/tree/master/examples/serverless-rest-example"
      }}>{`see the full code on GitHub`}</a>{`. I encourage you to play around and try deploying to your own AWS.`}</p>
    <p>{`Code samples here are excerpts.`}</p>
    <h3 {...{
      "id": "url-schema-mapped-to-lambdas"
    }}>{`URL schema mapped to Lambdas`}</h3>
    <p>{`We start with a URL schema and lambda function definitions in `}<inlineCode parentName="p">{`serverless.yml`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-yaml"
      }}>{`# serverless.yml

functions:
  getItems:
    handler: dist/manageItems.getItem
    events:
      - http:
          path: item/{itemId}
          method: GET
          cors: true
  updateItems:
    handler: dist/manageItems.updateItem
    events:
      - http:
          path: item
          method: POST
          cors: true
      - http:
          path: item/{itemId}
          method: POST
          cors: true
  deleteItems:
    handler: dist/manageItems.deleteItem
    events:
      - http:
          path: item/{itemId}
          method: DELETE
          cors: true
`}</code></pre>
    <p>{`This defines the 4 operations of a basic CRUD app:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`getItem`}</inlineCode>{` to fetch items`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`updateItem`}</inlineCode>{` to create items`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`updateItem/id`}</inlineCode>{` to update items`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`deleteItem`}</inlineCode>{` to delete items`}</li>
    </ul>
    <p>{`The `}<inlineCode parentName="p">{`{itemId}`}</inlineCode>{` syntax lets APIGateway parse the URL for us and pass identifiers to our code as parameters.`}</p>
    <p>{`Mapping every operation to its own lambda means you don't have to write routing code. When a lambda gets called, it knows what to do.`}</p>
    <p>{`We define lambdas in a `}<a parentName="p" {...{
        "href": "https://github.com/Swizec/serverlesshandbook.dev/blob/master/examples/serverless-rest-example/src/manageItems.ts"
      }}><inlineCode parentName="a">{`manageItems.ts`}</inlineCode>{` file`}</a>{`. Grouping operations from the same model helps keep your code organized.`}</p>
    <h3 {...{
      "id": "getitem"
    }}>{`getItem`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`// src/manageItems.ts

// fetch using /item/ID
export const getItem = async (event: APIGatewayEvent): Promise<APIResponse> => {
  const itemId = event.pathParameters ? event.pathParameters.itemId : ""

  const item = await db.getItem({
    TableName: process.env.ITEM_TABLE!,
    Key: { itemId },
  })

  if (item.Item) {
    return response(200, {
      status: "success",
      item: item.Item,
    })
  } else {
    return response(404, {
      status: "error",
      error: "Item not found",
    })
  }
}
`}</code></pre>
    <p>{`The `}<inlineCode parentName="p">{`getItem`}</inlineCode>{` function is triggered from an APIGatewayEvent. It includes a `}<inlineCode parentName="p">{`pathParameters`}</inlineCode>{` object that contains an `}<inlineCode parentName="p">{`itemId`}</inlineCode>{` parsed from the URL.`}</p>
    <p>{`We then use a `}<a parentName="p" {...{
        "href": "https://github.com/Swizec/serverlesshandbook.dev/blob/master/examples/serverless-rest-example/src/dynamodb.ts#L80"
      }}>{`DynamoDB `}<inlineCode parentName="a">{`getItem`}</inlineCode>{` wrapper`}</a>{` to talk to the database and find the requested item.`}</p>
    <p>{`If the item is found, we return a success response, otherwise an error. The `}<inlineCode parentName="p">{`response`}</inlineCode>{` method is a helper that makes responses easier to write.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`// src/manageItems.ts

function response(statusCode: number, body: any) {
  return {
    statusCode,
    body: JSON.stringify(body),
  }
}
`}</code></pre>
    <p><strong parentName="p">{`You can try it out here:`}</strong></p>
    <TestCloudFunction serviceName="serverless-rest-example" urlPlaceholder="https://4sklrwb1jg.execute-api.us-east-1.amazonaws.com/dev/item/0769413a-b306-46cb-a03c-5a8d5e29aa3e" defaults mdxType="TestCloudFunction" />
    <p>{`Or paste that URL into a browser.`}</p>
    <h3 {...{
      "id": "updateitem"
    }}>{`updateItem`}</h3>
    <p>{`Updating an item is the most complex operation we've got.`}</p>
    <p>{`It handles both creating and updating items, always assuming that the client knows best. You'll want to validate that in production.`}</p>
    <p>{`Here we overwrite server data with the client payload.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`// upsert an item
// /item or /item/ID
export const updateItem = async (
  event: APIGatewayEvent
): Promise<APIResponse> => {
  let itemId = event.pathParameters ? event.pathParameters.itemId : uuidv4()

  let createdAt = new Date().toISOString()

  if (event.pathParameters && event.pathParameters.itemId) {
    // find item if exists
    const find = await db.getItem({
      TableName: process.env.ITEM_TABLE!,
      Key: { itemId },
    })
    if (find.Item) {
      // save createdAt so we don't overwrite on update
      createdAt = find.Item.createdAt
    } else {
      return response(404, {
        status: "error",
        error: "Item not found",
      })
    }
  }

  if (!event.body) {
    return response(400, {
      status: "error",
      error: "Provide a JSON body",
    })
  }

  let body = JSON.parse(event.body)

  if (body.itemId) {
    // this will confuse DynamoDB, you can't update the key
    delete body.itemId
  }

  const item = await db.updateItem({
    TableName: process.env.ITEM_TABLE!,
    Key: { itemId },
    UpdateExpression: \`SET \${db.buildExpression(
      body
    )}, createdAt = :createdAt, lastUpdatedAt = :lastUpdatedAt\`,
    ExpressionAttributeValues: {
      ...db.buildAttributes(body),
      ":createdAt": createdAt,
      ":lastUpdatedAt": new Date().toISOString(),
    },
    ReturnValues: "ALL_NEW",
  })

  return response(200, {
    status: "success",
    item: item.Attributes,
  })
}
`}</code></pre>
    <p>{`The pattern we're following is:`}</p>
    <ul>
      <li parentName="ul">{`if no ID provided, generate one`}</li>
      <li parentName="ul">{`find item in DB`}</li>
      <li parentName="ul">{`update or create a `}<inlineCode parentName="li">{`createdAt`}</inlineCode>{` timestamp`}</li>
      <li parentName="ul">{`parse the JSON body`}</li>
      <li parentName="ul">{`create an update expression`}</li>
      <li parentName="ul">{`add a `}<inlineCode parentName="li">{`lastUpdatedAt`}</inlineCode>{` timestamp`}</li>
      <li parentName="ul">{`return the resulting object`}</li>
    </ul>
    <p>{`Adding `}<inlineCode parentName="p">{`createdAt`}</inlineCode>{` and `}<inlineCode parentName="p">{`lastUpdatedAt`}</inlineCode>{` timestamps is helpful when debugging these systems. You want to know when something happened.`}</p>
    <p>{`You can find the `}<inlineCode parentName="p">{`db.*`}</inlineCode>{` helper methods in my `}<a parentName="p" {...{
        "href": "https://github.com/Swizec/serverlesshandbook.dev/blob/master/examples/serverless-rest-example/src/dynamodb.ts"
      }}><inlineCode parentName="a">{`dynamodb.ts`}</inlineCode>{` file`}</a></p>
    <p><strong parentName="p">{`You can try it out here:`}</strong></p>
    <p>{`Create an item 👇`}</p>
    <TestCloudFunction serviceName="serverless-rest-example" urlPlaceholder="https://4sklrwb1jg.execute-api.us-east-1.amazonaws.com/dev/item" jsonPlaceholder={`{"hello_world": "this is a json item", "params": "It can have multiple params", "customNumber": 4}`} defaults mdxType="TestCloudFunction" />
    <p>{`Update an item, try using the ID from before 👇`}</p>
    <TestCloudFunction serviceName="serverless-rest-example" urlPlaceholder="https://4sklrwb1jg.execute-api.us-east-1.amazonaws.com/dev/item/e76e46f4-296d-4fef-a349-a1bb5717f2ac" jsonPlaceholder={`{"hello_world": "this is a json item", "params": "It can have multiple params", "customNumber": 4}`} defaults mdxType="TestCloudFunction" />
    <h3 {...{
      "id": "deleteitem"
    }}>{`deleteItem`}</h3>
    <p>{`Deleting is easy by comparison. Get the ID, delete the item. With no verification that you should or shouldn't be able to.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`// src/manageItems.ts

export const deleteItem = async (
  event: APIGatewayEvent
): Promise<APIResponse> => {
  const itemId = event.pathParameters ? event.pathParameters.itemId : null

  if (!itemId) {
    return response(400, {
      status: "error",
      error: "Provide an itemId",
    })
  }

  // DynamoDB handles deleting already deleted values, no error :)
  const item = await db.deleteItem({
    TableName: process.env.ITEM_TABLE!,
    Key: { itemId },
    ReturnValues: "ALL_OLD",
  })

  return response(200, {
    status: "success",
    itemWas: item.Attributes,
  })
}
`}</code></pre>
    <p>{`We get `}<inlineCode parentName="p">{`itemId`}</inlineCode>{` from the URL props and call a `}<inlineCode parentName="p">{`deleteItem`}</inlineCode>{` method on DynamoDB. The API returns the item as it was before deletion.`}</p>
    <p><em parentName="p">{`PS: in a production system you'll want to use soft deletes – mark as deleted and keep the data`}</em></p>
    <h2 {...{
      "id": "fin-️"
    }}>{`Fin ✌️`}</h2>
    <p>{`And that's 14 years of REST API experience condensed into 2000 words. My favorite is how much easier this is to implement using serverless than with Rails or Express.`}</p>
    <p>{`I love that you can have different lambda functions (whole servers, really) for individual endpoints.`}</p>
    <p>{`Next chapter, we look at GraphQL and why it represents an exciting future.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      